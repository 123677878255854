import React from 'react';
import { disciplinesByName } from 'constants/disciplines';
import { useSelector } from 'react-redux';
import CheckboxLine from 'components/CheckboxLine';
import { formatDate } from 'helpers';
import { getFilteredClassSegments } from '../../models/DisciplineSegment';

const SegmentsByClassCheckList = ({
  segments,
  disciplineTypeUID,
  onClassSegmentSelected,
  selectedClassSegments,
  isYouthAthlete,
  isFemaleAthlete,
}) => {
  const selectedClasses = useSelector(
    (state) => state.disciplineClass.selected,
  );

  const selectedCompetitions = useSelector(
    (state) => state.disciplineClass.selectedCompetitions,
  );

  const classes = selectedClasses.filter(
    (sc) => sc.value === disciplineTypeUID,
  );
  return (
    <>
      <div
        key={`discipline-segments-label-${disciplineTypeUID.toString()}`}
        className="discipline-name-area"
      >
        {disciplinesByName[disciplineTypeUID]}
      </div>
      <div>
        {classes.map((c) => {
          const classHasSelectedCompetitions = selectedCompetitions.some(
            (sc) => sc.className === c.ClassName && sc.disciplineId === c.value,
          );
          if (!classHasSelectedCompetitions) return null;
          const filteredSegments = getFilteredClassSegments(
            segments,
            !!isYouthAthlete,
            !!isFemaleAthlete,
            c.Division,
          );
          return (
            <div key={`key_${c.value}_${c.ClassName}`}>
              <span>{c.ClassName}</span>
              <small style={{ display: 'block', textTransform: 'none' }}>
                {c.DivisionName}
              </small>
              {filteredSegments.map((segment) => {
                const selected = !!selectedClassSegments.find(
                  (cs) =>
                    cs.disciplineTypeUID === disciplineTypeUID &&
                    cs.className === c.ClassName &&
                    cs.segment.value === segment.value,
                );
                const disabled = !!(
                  (segment.WomensOnly && c.BlockWomenSegment) ||
                  (segment.HasYouth && c.BlockYouthSegment)
                );
                return (
                  <div key={segment.value}>
                    <CheckboxLine
                      key={segment.value}
                      isChecked={selected}
                      option={segment}
                      disabled={disabled}
                      onChange={() =>
                        onClassSegmentSelected(
                          disciplineTypeUID,
                          c.ClassName,
                          segment,
                          c.DivisionName,
                          c.Division,
                        )
                      }
                    >
                      {(segment) => {
                        return (
                          <span className="segment-label">
                            <span className="segment-name">
                              {segment.label}
                            </span>
                            {!disabled && (
                              <span className="label">
                                {`${formatDate(
                                  segment.StartDate,
                                )} - ${formatDate(segment.EndDate)}`}
                              </span>
                            )}
                            {disabled && (
                              <span
                                className="label"
                                style={{ color: 'orange' }}
                              >
                                Nominations for this segment on NextGen
                              </span>
                            )}
                          </span>
                        );
                      }}
                    </CheckboxLine>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SegmentsByClassCheckList;
